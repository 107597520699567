import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./Ruler.module.css";

interface Props {
	enable: boolean;
}

export default function Ruler({ enable }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	//
	if (!enable) {
		return null;
	}
	return (
		<div id="Ruler" className={styles.ruler}>
			{isSmall && (
				<div id="smallRuler" className={styles.smallRuler}>
					<div className={styles.smallRulerCol}></div>
				</div>
			)}
			{isMedium && (
				<div id="mediumRuler" className={styles.mediumRuler}>
					<div className={styles.mediumRulerColLeft}></div>
					<div className={styles.mediumRulerColRight}></div>
				</div>
			)}
			{isLarge && (
				<div id="largeRuler" className={styles.largeRuler}>
					<div className={styles.largeRulerColLeft}></div>
					<div className={styles.largeRulerColInner}></div>
					<div className={styles.largeRulerColInner}></div>
					<div className={styles.largeRulerColRight}></div>
				</div>
			)}
		</div>
	);
}
