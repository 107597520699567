import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

import styles from "./SubItemVar.module.css";

interface Props {
	title: string;
}

const SubItemVariants4 = {
	hidden: {
		x: -20,
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
	},
};

export default function SubItemVar({ title }: Props) {
	return (
		<AnimatePresence>
			<motion.div
				className={styles.subItem}
				layout
				variants={SubItemVariants4}
			>
				<div className={styles.textWrapper}>
					<div className={styles.text}>{title}</div>
				</div>
			</motion.div>
		</AnimatePresence>
	);
}
