import { ReactNode } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Ruler from "../Ruler/Ruler";
import styles from "./Section.module.css";

interface Props {
	theme: string;
	ruler: boolean;
	padding: string;
	children?: ReactNode;
}

export default function Section({ theme, ruler, padding, children }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="Section" className={styles.section} data-theme={theme}>
			<Ruler enable={ruler} />
			{ruler && <div className={styles.sectionTopRuler}></div>}
			{isSmall && (
				<div
					id="smallSection"
					className={styles.smallSection}
					data-padding={padding}
				>
					{children}
				</div>
			)}
			{isMedium && (
				<div
					id="mediumSection"
					className={styles.mediumSection}
					data-padding={padding}
				>
					{children}
				</div>
			)}
			{isLarge && (
				<div
					id="largeSection"
					className={styles.largeSection}
					data-padding={padding}
				>
					{children}
				</div>
			)}
		</div>
	);
}
