import { motion } from "framer-motion";

import SubItem from "../SubItem/SubItem";
import styles from "./Menu.module.css";

const MenuItemVariants = {
	hidden: {
		opacity: 0,
		y: -8,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	exit: {
		opacity: 0,
		y: -8,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const MenuItemVariants2 = {
	visible: {
		transition: {
			delayChildren: 0.2,
			staggerChildren: 0.02,
		},
	},
};

interface Props {
	currentMenu: null | string;
	menuOpen: boolean;
}

export default function Menu({ currentMenu, menuOpen }: Props) {
	return (
		<motion.div
			className={styles.navigationMenu}
			layout
			initial="hidden"
			animate={menuOpen ? "visible" : "hidden"}
			exit="exit"
			variants={MenuItemVariants}
			style={{
				borderRadius: "16px",
				boxShadow:
					"0 13.5px 27px -12.4px #32325d40, 0 8.1px 16.2px -8.1px #0000001a",
				border: "1px solid rgb(227, 232, 238)",
			}}
		>
			{currentMenu === "Products" && (
				<motion.div
					className={styles.subItemContainerTest}
					layout
					initial="hidden"
					animate="visible"
					variants={MenuItemVariants2}
				>
					<SubItem
						title="Car Insurance"
						href="/products/car"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_2)">
								<path
									d="M8 10H16M7 14H8M16 14H17"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3 18V11.41C3.00021 11.1394 3.05532 10.8717 3.162 10.623L5.481 5.213C5.63504 4.85313 5.8913 4.54637 6.21802 4.33075C6.54473 4.11513 6.92755 4.00013 7.319 4H16.681C17.0723 4.00004 17.4551 4.1149 17.7818 4.33033C18.1085 4.54576 18.3648 4.85231 18.519 5.212L20.839 10.622C20.9456 10.8714 21.0004 11.1398 21 11.411V18M3 18V20.4C3 20.5591 3.06321 20.7117 3.17574 20.8243C3.28826 20.9368 3.44087 21 3.6 21H6.4C6.55913 21 6.71174 20.9368 6.82426 20.8243C6.93679 20.7117 7 20.5591 7 20.4V18M3 18H7M21 18V20.4C21 20.5591 20.9368 20.7117 20.8243 20.8243C20.7117 20.9368 20.5591 21 20.4 21H17.6C17.5212 21 17.4432 20.9845 17.3704 20.9543C17.2976 20.9242 17.2315 20.88 17.1757 20.8243C17.12 20.7685 17.0758 20.7024 17.0457 20.6296C17.0155 20.5568 17 20.4788 17 20.4V18M21 18H17M7 18H17"
									stroke="currentColor"
									strokeWidth="1.5"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Home Insurance"
						href="/products/home"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_10)">
								<path
									d="M3 9.5L12 4L21 9.5M19 13V19.4C19 19.5591 18.9368 19.7117 18.8243 19.8243C18.7117 19.9368 18.5591 20 18.4 20H5.6C5.44087 20 5.28826 19.9368 5.17574 19.8243C5.06321 19.7117 5 19.5591 5 19.4V13"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M12.502 9.13L14.551 9.661C14.815 9.73 15.001 9.97 14.992 10.243C14.826 15.232 12 16 12 16C12 16 9.174 15.232 9.008 10.243C9.00443 10.1103 9.04616 9.98037 9.12632 9.87458C9.20649 9.76878 9.3203 9.69345 9.449 9.661L11.499 9.131C11.8279 9.0458 12.1731 9.0458 12.502 9.131V9.13Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Car and Home"
						href="/products/carhome"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_17)">
								<path
									d="M6 20H3V6L12 4L21 6V20H18M6 20H18M6 20V16M18 20V16M6 12V8H18V12M6 12H18M6 12V16M18 12V16M6 16H18"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Condo Insurance"
						href="/products/condo"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_116)">
								<path
									d="M10 9.01L10.01 8.999M14 9.01L14.01 8.999M10 13.01L10.01 12.999M14 13.01L14.01 12.999M10 17.01L10.01 16.999M14 17.01L14.01 16.999M6 20.4V5.6C6 5.44087 6.06321 5.28826 6.17574 5.17574C6.28826 5.06321 6.44087 5 6.6 5H12V3.6C12 3.44087 12.0632 3.28826 12.1757 3.17574C12.2883 3.06321 12.4409 3 12.6 3H17.4C17.5591 3 17.7117 3.06321 17.8243 3.17574C17.9368 3.28826 18 3.44087 18 3.6V20.4C18 20.5591 17.9368 20.7117 17.8243 20.8243C17.7117 20.9368 17.5591 21 17.4 21H6.6C6.44087 21 6.28826 20.9368 6.17574 20.8243C6.06321 20.7117 6 20.5591 6 20.4V20.4Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Renters Insurance"
						href="/products/renters"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_112)">
								<path
									d="M7 9.01L7.01 8.999M11 9.01L11.01 8.999M7 13.01L7.01 12.999M11 13.01L11.01 12.999M7 17.01L7.01 16.999M11 17.01L11.01 16.999M15 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4V5.6C3 5.44087 3.06321 5.28826 3.17574 5.17574C3.28826 5.06321 3.44087 5 3.6 5H9V3.6C9 3.44087 9.06321 3.28826 9.17574 3.17574C9.28826 3.06321 9.44087 3 9.6 3H14.4C14.5591 3 14.7117 3.06321 14.8243 3.17574C14.9368 3.28826 15 3.44087 15 3.6V9M15 21H20.4C20.5591 21 20.7117 20.9368 20.8243 20.8243C20.9368 20.7117 21 20.5591 21 20.4V9.6C21 9.44087 20.9368 9.28826 20.8243 9.17574C20.7117 9.06321 20.5591 9 20.4 9H15M15 21V17M15 9V13M15 13H17M15 13V17M15 17H17"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Aircraft"
						href="/products/aircraft"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_44)">
								<path
									d="M10.5 4.5V9.167C10.5002 9.26897 10.4744 9.3693 10.4251 9.45854C10.3757 9.54777 10.3045 9.62296 10.218 9.677L2.782 14.324C2.69582 14.3779 2.62473 14.4527 2.57541 14.5416C2.52608 14.6304 2.50014 14.7304 2.5 14.832V15.732C2.49999 15.8232 2.52079 15.9132 2.56081 15.9952C2.60082 16.0772 2.65901 16.149 2.73093 16.2051C2.80286 16.2612 2.88663 16.3002 2.97589 16.3191C3.06514 16.3379 3.15752 16.3362 3.246 16.314L9.754 14.686C9.84248 14.6638 9.93486 14.6621 10.0241 14.6809C10.1134 14.6998 10.1971 14.7388 10.2691 14.7949C10.341 14.851 10.3992 14.9228 10.4392 15.0048C10.4792 15.0868 10.5 15.1768 10.5 15.268V18.228C10.4999 18.3134 10.4816 18.3979 10.4462 18.4756C10.4109 18.5534 10.3593 18.6228 10.295 18.679L8.135 20.569C7.677 20.971 8.038 21.72 8.637 21.611L11.893 21.02C11.9638 21.0072 12.0362 21.0072 12.107 21.02L15.363 21.611C15.962 21.721 16.323 20.971 15.865 20.57L13.705 18.68C13.6406 18.6236 13.5889 18.5542 13.5536 18.4762C13.5182 18.3982 13.4999 18.3136 13.5 18.228V15.268C13.5 15.1769 13.5208 15.087 13.5608 15.0051C13.6007 14.9232 13.6588 14.8515 13.7306 14.7954C13.8024 14.7393 13.8861 14.7003 13.9752 14.6813C14.0643 14.6624 14.1566 14.664 14.245 14.686L20.755 16.314C20.8434 16.336 20.9357 16.3376 21.0248 16.3187C21.1139 16.2997 21.1976 16.2607 21.2694 16.2046C21.3412 16.1485 21.3993 16.0768 21.4392 15.9949C21.4792 15.913 21.5 15.8231 21.5 15.732V14.832C21.4999 14.7304 21.4739 14.6304 21.4246 14.5416C21.3753 14.4527 21.3042 14.3779 21.218 14.324L13.782 9.676C13.6957 9.62205 13.6245 9.54702 13.5752 9.45797C13.5258 9.36893 13.5 9.2688 13.5 9.167V4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5V4.5Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Watercraft"
						href="/products/watercraft"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_2_133)">
								<path
									d="M3 20C3 20 6 22.5 12 20C18 17.5 21 20 21 20"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5 17L3 12.1948C3 12.1431 3.08429 12.0936 3.23431 12.0571C3.38434 12.0205 3.58783 12 3.8 12H11"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M19 17L21 12.1948C21 12.1431 20.8946 12.0936 20.7071 12.0571C20.5196 12.0205 20.2652 12 20 12H11"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14 12L14 3L5 12"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="General Liability"
						href="/products/generalliability"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_55)">
								<path
									d="M3 10V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10"
									stroke="currentColor"
									strokeWidth="1.5"
								/>
								<path
									d="M14.833 21V15C14.833 14.4696 14.6223 13.9609 14.2472 13.5858C13.8721 13.2107 13.3634 13 12.833 13H10.833C10.3026 13 9.79387 13.2107 9.41879 13.5858C9.04372 13.9609 8.83301 14.4696 8.83301 15V21"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeMiterlimit="16"
								/>
								<path
									d="M21.818 9.364L20.124 3.435C20.0881 3.30965 20.0124 3.19939 19.9083 3.1209C19.8042 3.04241 19.6774 2.99997 19.547 3H15.5L15.975 8.704C15.9822 8.79572 16.0112 8.8844 16.0595 8.96267C16.1079 9.04093 16.1742 9.10653 16.253 9.154C16.643 9.387 17.405 9.817 18 10C19.016 10.313 20.5 10.2 21.346 10.096C21.4282 10.0854 21.5072 10.0569 21.5773 10.0126C21.6474 9.96835 21.707 9.90929 21.752 9.8396C21.7969 9.7699 21.8261 9.69123 21.8375 9.60909C21.8489 9.52695 21.8423 9.44331 21.818 9.364Z"
									stroke="currentColor"
									strokeWidth="1.5"
								/>
								<path
									d="M14 10C14.568 9.825 15.288 9.426 15.69 9.188C15.7837 9.13222 15.8597 9.05108 15.9093 8.95393C15.9588 8.85678 15.9799 8.7476 15.97 8.639L15.5 3H8.5L8.03 8.639C8.01994 8.74777 8.04089 8.85716 8.09045 8.9545C8.14001 9.05184 8.21613 9.13315 8.31 9.189C8.712 9.426 9.432 9.825 10 10C11.493 10.46 12.507 10.46 14 10V10Z"
									stroke="currentColor"
									strokeWidth="1.5"
								/>
								<path
									d="M3.87601 3.435L2.18201 9.365C2.1581 9.44418 2.1517 9.52762 2.16327 9.60951C2.17484 9.69141 2.2041 9.76981 2.24901 9.83927C2.29392 9.90873 2.35341 9.96758 2.42334 10.0117C2.49328 10.0559 2.57199 10.0843 2.65401 10.095C3.49901 10.2 4.98401 10.312 6.00001 10C6.59501 9.817 7.35801 9.387 7.74701 9.155C7.82594 9.10743 7.89236 9.04168 7.94072 8.96323C7.98908 8.88477 8.01798 8.79589 8.02501 8.704L8.50001 3H4.45301C4.32263 2.99997 4.19579 3.04241 4.09168 3.1209C3.98757 3.19939 3.91186 3.30965 3.87601 3.435V3.435Z"
									stroke="currentColor"
									strokeWidth="1.5"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Workers Comp"
						href="/products/workerscomp"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_87)">
								<path
									d="M18 10C18 9 17 8 15 8H14C13.2044 8 12.4413 7.68393 11.8787 7.12132C11.3161 6.55871 11 5.79565 11 5V2M18 21H21V12H18V16.5M18 21V16.5M18 21H3V17L6.5 14L10.5 16.5L14.5 14L18 16.5M21 10C21 4 17 4 17 4C17 4 21 4.5 21 2"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Commercial Auto"
						href="/products/commercialauto"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_1_49)">
								<path
									d="M7 19C7.53043 19 8.03914 18.7893 8.41421 18.4142C8.78929 18.0391 9 17.5304 9 17C9 16.4696 8.78929 15.9609 8.41421 15.5858C8.03914 15.2107 7.53043 15 7 15C6.46957 15 5.96086 15.2107 5.58579 15.5858C5.21071 15.9609 5 16.4696 5 17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19V19ZM17 19C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17C19 16.4696 18.7893 15.9609 18.4142 15.5858C18.0391 15.2107 17.5304 15 17 15C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17C15 17.5304 15.2107 18.0391 15.5858 18.4142C15.9609 18.7893 16.4696 19 17 19V19Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeMiterlimit="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14 17V6.6C14 6.44087 13.9368 6.28826 13.8243 6.17574C13.7117 6.06321 13.5591 6 13.4 6H2.6C2.44087 6 2.28826 6.06321 2.17574 6.17574C2.06321 6.28826 2 6.44087 2 6.6V16.4C2 16.4788 2.01552 16.5568 2.04567 16.6296C2.07583 16.7024 2.12002 16.7685 2.17574 16.8243C2.23145 16.88 2.29759 16.9242 2.37039 16.9543C2.44319 16.9845 2.52121 17 2.6 17H4.65M14 17H9.05M14 9H19.61C19.726 9.00003 19.8395 9.03367 19.9367 9.09685C20.034 9.16003 20.1108 9.25005 20.158 9.356L21.948 13.384C21.9821 13.4605 21.9998 13.5433 22 13.627V16.4C22 16.4788 21.9845 16.5568 21.9543 16.6296C21.9242 16.7024 21.88 16.7685 21.8243 16.8243C21.7685 16.88 21.7024 16.9242 21.6296 16.9543C21.5568 16.9845 21.4788 17 21.4 17H19.5M14 17H15"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="More Choices"
						href="https://rater.gia-usa.com/contact"
						internal={false}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<path
								d="M7 12.5C7.13261 12.5 7.25979 12.4473 7.35355 12.3536C7.44732 12.2598 7.5 12.1326 7.5 12C7.5 11.8674 7.44732 11.7402 7.35355 11.6464C7.25979 11.5527 7.13261 11.5 7 11.5C6.86739 11.5 6.74021 11.5527 6.64645 11.6464C6.55268 11.7402 6.5 11.8674 6.5 12C6.5 12.1326 6.55268 12.2598 6.64645 12.3536C6.74021 12.4473 6.86739 12.5 7 12.5V12.5ZM12 12.5C12.1326 12.5 12.2598 12.4473 12.3536 12.3536C12.4473 12.2598 12.5 12.1326 12.5 12C12.5 11.8674 12.4473 11.7402 12.3536 11.6464C12.2598 11.5527 12.1326 11.5 12 11.5C11.8674 11.5 11.7402 11.5527 11.6464 11.6464C11.5527 11.7402 11.5 11.8674 11.5 12C11.5 12.1326 11.5527 12.2598 11.6464 12.3536C11.7402 12.4473 11.8674 12.5 12 12.5ZM17 12.5C17.1326 12.5 17.2598 12.4473 17.3536 12.3536C17.4473 12.2598 17.5 12.1326 17.5 12C17.5 11.8674 17.4473 11.7402 17.3536 11.6464C17.2598 11.5527 17.1326 11.5 17 11.5C16.8674 11.5 16.7402 11.5527 16.6464 11.6464C16.5527 11.7402 16.5 11.8674 16.5 12C16.5 12.1326 16.5527 12.2598 16.6464 12.3536C16.7402 12.4473 16.8674 12.5 17 12.5Z"
								fill="currentColor"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</SubItem>
				</motion.div>
			)}
			{currentMenu === "Resources" && (
				<motion.div
					className={styles.subItemContainerTest}
					layoutId="menu2"
					initial="hidden"
					animate="visible"
					variants={MenuItemVariants2}
				>
					<SubItem
						title="About"
						href="/resources/about"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_88)">
								<path
									d="M4 19V5C4 4.46957 4.21071 3.96086 4.58579 3.58579C4.96086 3.21071 5.46957 3 6 3H19.4C19.5591 3 19.7117 3.06321 19.8243 3.17574C19.9368 3.28826 20 3.44087 20 3.6V16.714"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M8 3V11L10.5 9.4L13 11V3"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 17H20M6 21H20"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M6 21C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19C4 18.4696 4.21071 17.9609 4.58579 17.5858C4.96086 17.2107 5.46957 17 6 17"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Contact"
						href="https://rater.gia-usa.com/contact"
						internal={false}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_67)">
								<path
									d="M7.50001 22C8.71071 21.9998 9.88753 21.6001 10.8479 20.863C11.8084 20.1258 12.4987 19.0924 12.8119 17.9229C13.1251 16.7534 13.0437 15.5132 12.5803 14.3947C12.1169 13.2762 11.2973 12.3419 10.2488 11.7366C9.20023 11.1314 7.98127 10.889 6.78094 11.0471C5.5806 11.2052 4.46598 11.7549 3.60991 12.6111C2.75385 13.4672 2.2042 14.5819 2.04619 15.7822C1.88819 16.9826 2.13066 18.2015 2.73601 19.25L2.27501 21.725L4.75001 21.265C5.58583 21.7482 6.53457 22.0018 7.50001 22V22Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M15.282 17.898C16.2395 17.7439 17.1607 17.4158 18 16.93L21.6 17.6L20.93 14C21.5183 12.9795 21.8739 11.8416 21.9716 10.6678C22.0692 9.49388 21.9064 8.31292 21.4948 7.20924C21.0832 6.10556 20.4329 5.1064 19.5905 4.28312C18.748 3.45983 17.7341 2.83275 16.6213 2.44665C15.5084 2.06056 14.324 1.92498 13.1527 2.04961C11.9814 2.17423 10.852 2.55598 9.84531 3.16759C8.8386 3.7792 7.97938 4.60558 7.32901 5.58769C6.67864 6.5698 6.25318 7.68342 6.08301 8.849"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Claims"
						href="https://rater.gia-usa.com/contact"
						internal={false}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_82)">
								<path
									d="M12 8V12M12 16.01L12.01 15.999M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.821 2.487 15.53 3.338 17L2.5 21.5L7 20.662C8.51954 21.5411 10.2445 22.0027 12 22V22Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Privacy Policy"
						href="/resources/privacy"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_59)">
								<path
									d="M21.8 14C20.873 18.564 16.838 22 12 22C6.477 22 2 17.523 2 12C2 6.815 5.947 2.551 11 2.05"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7 15.01L7.01 14.999M17 15.01L17.01 14.999M11 12.01L11.01 11.999M21 9.01L21.01 8.999M17 6.01L17.01 5.999M11 2C10.5 3.5 11.5 5 13.085 5C11 8.5 13 12 18 11.5C18 14 20.5 14.5 21.7 14.014M6.5 10C6.36739 10 6.24021 9.94732 6.14645 9.85355C6.05268 9.75979 6 9.63261 6 9.5C6 9.36739 6.05268 9.24021 6.14645 9.14645C6.24021 9.05268 6.36739 9 6.5 9C6.63261 9 6.75979 9.05268 6.85355 9.14645C6.94732 9.24021 7 9.36739 7 9.5C7 9.63261 6.94732 9.75979 6.85355 9.85355C6.75979 9.94732 6.63261 10 6.5 10ZM20.5 4C20.3674 4 20.2402 3.94732 20.1464 3.85355C20.0527 3.75979 20 3.63261 20 3.5C20 3.36739 20.0527 3.24021 20.1464 3.14645C20.2402 3.05268 20.3674 3 20.5 3C20.6326 3 20.7598 3.05268 20.8536 3.14645C20.9473 3.24021 21 3.36739 21 3.5C21 3.63261 20.9473 3.75979 20.8536 3.85355C20.7598 3.94732 20.6326 4 20.5 4ZM12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Terms"
						href="/resources/terms"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_55)">
								<path
									d="M6 6H18M6 10H18M13 14H18M13 18H18M2 21.4V2.6C2 2.44087 2.06321 2.28826 2.17574 2.17574C2.28826 2.06321 2.44087 2 2.6 2H21.4C21.5591 2 21.7117 2.06321 21.8243 2.17574C21.9368 2.28826 22 2.44087 22 2.6V21.4C22 21.5591 21.9368 21.7117 21.8243 21.8243C21.7117 21.9368 21.5591 22 21.4 22H2.6C2.44087 22 2.28826 21.9368 2.17574 21.8243C2.06321 21.7117 2 21.5591 2 21.4V21.4Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 18V14H9V18H6Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
				</motion.div>
			)}
			{currentMenu === "Agents" && (
				<motion.div
					className={styles.subItemContainerTest}
					layoutId="menu2"
					initial="hidden"
					animate="visible"
					variants={MenuItemVariants2}
				>
					<SubItem
						title="Insurance"
						href="/agents/wholesaleinsurance"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_17_108)">
								<path
									d="M14 5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0392 3.21071 12.5304 3 12 3C11.4696 3 10.9609 3.21071 10.5858 3.58579C10.2107 3.96086 10 4.46957 10 5M19.26 9.696L20.645 18.696C20.6888 18.9808 20.6705 19.2718 20.5914 19.5489C20.5123 19.8261 20.3743 20.0828 20.1867 20.3016C19.9992 20.5204 19.7665 20.6961 19.5047 20.8167C19.243 20.9372 18.9582 20.9997 18.67 21H5.33001C5.04164 21 4.75668 20.9377 4.49465 20.8173C4.23263 20.6969 3.99974 20.5212 3.81196 20.3024C3.62417 20.0836 3.48593 19.8267 3.40672 19.5494C3.3275 19.2721 3.30918 18.981 3.35301 18.696L4.73801 9.696C4.81066 9.22359 5.05009 8.79282 5.41294 8.4817C5.77578 8.17059 6.23805 7.9997 6.71601 8H17.284C17.7618 7.99994 18.2238 8.17094 18.5865 8.48203C18.9491 8.79312 19.1884 9.22376 19.261 9.696H19.26Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
				</motion.div>
			)}
		</motion.div>
	);
}
