import Head from "next/head";

import { ReactNode } from "react";

import Layout from "../Layout/Layout";

interface Props {
	title: string;
	description: string;
	children?: ReactNode;
}

export default function Page({ title, description, children }: Props) {
	return (
		<div id="Page">
			<Head>
				<title>{title}</title>
				<meta name="description" content={description} />
				<link rel="icon" href="/icons/gia_favicon.png" />
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://gia-usa.com"></meta>
				<meta
					property="og:title"
					content={title + " | Global Insurance Agency"}
				></meta>
				<meta
					property="og:description"
					content={description + " | Global Insurance Agency"}
				></meta>
				<meta
					property="og:image"
					content="./meta_image_base.png"
				></meta>
				<meta
					property="twitter:card"
					content="summary_large_image"
				></meta>
				<meta
					property="twitter:url"
					content="https://gia-usa.com"
				></meta>
				<meta
					property="twitter:title"
					content={title + " | Global Insurance Agency"}
				></meta>
				<meta
					property="twitter:description"
					content={description + " | Global Insurance Agency"}
				></meta>
				<meta
					property="twitter:image"
					content="./meta_image_base.png"
				></meta>
			</Head>
			<Layout>{children}</Layout>
		</div>
	);
}
