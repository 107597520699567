import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";

import Menu from "../Menu/Menu";
import NavigationItem from "../NavigationItem/NavigationItem";
import styles from "./Navigation.module.css";

export default function Navigation() {
	const [menuOpen, setMenuOpen] = useState(false);
	const [currentMenu, setCurrentMenu] = useState<null | string>(null);
	return (
		<nav
			className={styles.navigationContainer}
			onMouseEnter={() => setMenuOpen(true)}
			onMouseLeave={() => {
				setCurrentMenu(null);
				setMenuOpen(false);
			}}
		>
			<motion.ul className={styles.navigationContainerList}>
				<NavigationItem
					text="Products"
					onMouseEnter={() => setCurrentMenu("Products")}
					currentMenu={currentMenu}
				></NavigationItem>
				<NavigationItem
					text="Resources"
					onMouseEnter={() => setCurrentMenu("Resources")}
					currentMenu={currentMenu}
				></NavigationItem>
				<NavigationItem
					text="Agents"
					onMouseEnter={() => setCurrentMenu("Agents")}
					currentMenu={currentMenu}
				></NavigationItem>
			</motion.ul>
			<AnimatePresence>
				{menuOpen && (
					<Menu currentMenu={currentMenu} menuOpen={menuOpen} />
				)}
			</AnimatePresence>
		</nav>
	);
}
