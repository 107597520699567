import { ReactNode } from "react";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Main from "../Main/Main";

interface Props {
	children?: ReactNode;
}

export default function Layout({ children }: Props) {
	return (
		<div id="Layout">
			<Header />
			<Main>{children}</Main>
			<Footer />
		</div>
	);
}
