import Image from "next/image";
import { StaticImageData } from "next/image";

import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./Picture.module.css";

interface Props {
	src: StaticImageData;
	alt: string;
}

export default function Picture({ src, alt }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="Picture" className={styles.picture}>
			{isSmall && (
				<div id="smallPicture" className={styles.smallPicture}>
					<div className={styles.smallPictureOverlay}></div>
					<picture className={styles.smallPictureContainer}>
						<Image
							src={src}
							alt={alt}
							placeholder="blur"
							quality={75}
							className={styles.smallPictureContainerImage}
							fill
						/>
					</picture>
				</div>
			)}
			{isMedium && (
				<div id="mediumPicture" className={styles.mediumPicture}>
					<div className={styles.mediumPictureOverlay}></div>
					<picture className={styles.mediumPictureContainer}>
						<Image
							src={src}
							alt={alt}
							placeholder="blur"
							quality={75}
							className={styles.mediumPictureContainerImage}
							fill
						/>
					</picture>
				</div>
			)}
			{isLarge && (
				<div id="largePicture" className={styles.largePicture}>
					<div className={styles.largePictureOverlay}></div>
					<picture className={styles.largePictureContainer}>
						<Image
							src={src}
							alt={alt}
							placeholder="blur"
							quality={75}
							className={styles.largePictureContainerImage}
							fill
						/>
					</picture>
				</div>
			)}
		</div>
	);
}
