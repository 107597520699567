import { motion } from "framer-motion";
import { MouseEventHandler } from "react";

import styles from "./MenuButton.module.css";

const SubItemVariants3 = {
	hidden: {
		backgroundColor: "#2563eb",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		backgroundColor: "#0a2540",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const top = {
	hidden: {
		rotate: 0,
		translateY: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		rotate: -45,
		translateY: 5,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};
const center = {
	hidden: {
		opacity: 1,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		opacity: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};
const bottom = {
	hidden: {
		rotate: 0,
		translateY: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		rotate: 45,
		translateY: -5,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

interface Props {
	onClick: MouseEventHandler;
	menuOpen: boolean;
}

export default function MenuButton({ onClick, menuOpen }: Props) {
	return (
		<motion.button
			onClick={onClick}
			className={styles.action}
			variants={SubItemVariants3}
			initial="hidden"
			animate={menuOpen ? "visible" : "hidden"}
		>
			<motion.svg
				viewBox="0 0 16 10"
				overflow="visible"
				preserveAspectRatio="none"
				width="16"
				height="10"
				className={styles.arrow}
			>
				<title>Open mobile navigation</title>
				<motion.line
					x1="0"
					x2="16"
					y1="0"
					y2="0"
					variants={top}
					initial="hidden"
					animate={menuOpen ? "visible" : "hidden"}
					fill="none"
					stroke="currentColor"
					strokeWidth={2}
					style={{ color: "#ffffff" }}
				/>
				<motion.line
					x1="0"
					x2="16"
					y1="5"
					y2="5"
					variants={center}
					initial="hidden"
					animate={menuOpen ? "visible" : "hidden"}
					fill="none"
					stroke="currentColor"
					strokeWidth={2}
					style={{ color: "#ffffff" }}
				/>
				<motion.line
					x1="0"
					x2="16"
					y1="10"
					y2="10"
					variants={bottom}
					initial="hidden"
					animate={menuOpen ? "visible" : "hidden"}
					fill="none"
					stroke="currentColor"
					strokeWidth={2}
					style={{ color: "#ffffff" }}
				/>
			</motion.svg>
		</motion.button>
	);
}
