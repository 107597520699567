import Link from "next/link";

import { motion } from "framer-motion";
import { ReactNode } from "react";
import { useState } from "react";

import styles from "./Button.module.css";

const SubItemVariants = {
	hidden: {
		x: -4,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		x: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const SubItemVariants2 = {
	hidden: {
		x: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		x: 3,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const SubItemVariants3 = {
	hidden: {
		backgroundColor: "#2e63eb",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		backgroundColor: "#0a2540",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

interface Props {
	internal: boolean;
	href: string;
	children: ReactNode;
}

export default function Button({ internal, href, children }: Props) {
	const [isHovered, setIsHovered] = useState(false);
	return (
        <div className={styles.buttonWrapper}>
			{internal && (
				(<Link href={href} passHref>

                    <motion.button
                        onHoverStart={() => setIsHovered(true)}
                        onHoverEnd={() => {
                            setIsHovered(false);
                        }}
                        className={styles.action}
                        variants={SubItemVariants3}
                        initial="hidden"
                        animate={isHovered ? "visible" : "hidden"}
                    >
                        <span className={styles.text}>{children}</span>
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            className={styles.arrow}
                        >
                            <g fillRule="evenodd">
                                {isHovered && (
                                    <motion.path
                                        d="M0 5h7"
                                        fill="none"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        variants={SubItemVariants}
                                        initial="hidden"
                                        animate={
                                            isHovered ? "visible" : false
                                        }
                                    ></motion.path>
                                )}
                                <motion.path
                                    d="M1 1l4 4-4 4"
                                    fill="none"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    variants={SubItemVariants2}
                                    initial="hidden"
                                    animate={
                                        isHovered ? "visible" : "hidden"
                                    }
                                ></motion.path>
                            </g>
                        </svg>
                    </motion.button>

                </Link>)
			)}
			{!internal && (
				<a href={href}>
					<motion.button
						onHoverStart={() => setIsHovered(true)}
						onHoverEnd={() => {
							setIsHovered(false);
						}}
						className={styles.action}
						variants={SubItemVariants3}
						initial="hidden"
						animate={isHovered ? "visible" : "hidden"}
					>
						<span className={styles.text}>{children}</span>
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							className={styles.arrow}
						>
							<g fillRule="evenodd">
								{isHovered && (
									<motion.path
										d="M0 5h7"
										fill="none"
										strokeWidth={2}
										stroke="currentColor"
										variants={SubItemVariants}
										initial="hidden"
										animate={isHovered ? "visible" : false}
									></motion.path>
								)}
								<motion.path
									d="M1 1l4 4-4 4"
									fill="none"
									strokeWidth={2}
									stroke="currentColor"
									variants={SubItemVariants2}
									initial="hidden"
									animate={isHovered ? "visible" : "hidden"}
								></motion.path>
							</g>
						</svg>
					</motion.button>
				</a>
			)}
		</div>
    );
}
