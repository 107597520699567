import { ReactNode } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./Grid.module.css";

interface Props {
	smallColumnNumber: number;
	smallRowNumber: number;
	mediumColumnNumber: number;
	mediumRowNumber: number;
	largeColumnNumber: number;
	largeRowNumber: number;
	smallColumnGap: string;
	smallRowGap: string;
	mediumColumnGap: string;
	mediumRowGap: string;
	largeColumnGap: string;
	largeRowGap: string;
	smallWidth: number;
	mediumWidth: number;
	largeWidth: number;
	justify: string;
	align: string;
	children?: ReactNode;
}

export default function Grid({
	smallColumnNumber,
	smallRowNumber,
	mediumColumnNumber,
	mediumRowNumber,
	largeColumnNumber,
	largeRowNumber,
	smallColumnGap,
	smallRowGap,
	mediumColumnGap,
	mediumRowGap,
	largeColumnGap,
	largeRowGap,
	smallWidth,
	mediumWidth,
	largeWidth,
	justify,
	align,
	children,
}: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="Grid" className={styles.grid}>
			{isSmall && (
				<div
					id="smallGrid"
					className={styles.smallGrid}
					data-column-number={smallColumnNumber}
					data-row-number={smallRowNumber}
					data-column-gap={smallColumnGap}
					data-row-gap={smallRowGap}
					data-width={smallWidth}
					data-justify={justify}
					data-align={align}
				>
					{children}
				</div>
			)}
			{isMedium && (
				<div
					id="mediumGrid"
					className={styles.mediumGrid}
					data-column-number={mediumColumnNumber}
					data-row-number={mediumRowNumber}
					data-column-gap={mediumColumnGap}
					data-row-gap={mediumRowGap}
					data-width={mediumWidth}
					data-justify={justify}
					data-align={align}
				>
					{children}
				</div>
			)}
			{isLarge && (
				<div
					id="largeGrid"
					className={styles.largeGrid}
					data-column-number={largeColumnNumber}
					data-row-number={largeRowNumber}
					data-column-gap={largeColumnGap}
					data-row-gap={largeRowGap}
					data-width={largeWidth}
					data-justify={justify}
					data-align={align}
				>
					{children}
				</div>
			)}
		</div>
	);
}
