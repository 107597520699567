import { motion } from "framer-motion";
import { ReactNode } from "react";
import { MouseEventHandler } from "react";

import styles from "./NavigationItem.module.css";

interface Props {
	text: string;
	onMouseEnter: MouseEventHandler;
	currentMenu: null | string;
	children?: ReactNode;
}

const MenuItemVariants = {
	initial: {
		opacity: 1,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.05,
		},
	},
	animate: {
		opacity: 0.7,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.05,
		},
	},
};

export default function NavigationItem({
	text,
	onMouseEnter,
	currentMenu,
}: Props) {
	return (
		<motion.li
			className={styles.navigationItem}
			onMouseEnter={onMouseEnter}
			initial="initial"
			animate={currentMenu === text ? "animate" : "initial"}
			variants={MenuItemVariants}
		>
			<div className={styles.navigationItemTest}>
				<div>{text}</div>
			</div>
		</motion.li>
	);
}
